/**
 * @see https://stackoverflow.com/questions/43557755/how-to-call-ajax-in-wordpress
 */
(function() {


    


    function fetch_results($link, $wrapper)
    {
        function handle_response()
        {
            if (this.status >= 200 && this.status < 400) {
                var parser = new DOMParser();
                var doc = parser.parseFromString(this.response, 'text/html');
                const $load_more = $link.closest('.archive-listing__load-more');
                if ($load_more) {
                    var results = doc.querySelector('.archive-listing');
                    var $new_load_more = doc.querySelector('.archive-listing__load-more');
                    var $new_items = doc.querySelectorAll('.archive-listing__item');
                    const $items_wrapper = $wrapper.querySelector('.archive-listing__list');
                    const initial_length = $items_wrapper.childNodes.length;
                    $items_wrapper.append(...$new_items);
                    if ($new_load_more) {
                        $load_more.innerHTML = $new_load_more.innerHTML;
                    } else {
                        $load_more.remove();
                    }
                    const $to_focus = $items_wrapper.childNodes[initial_length];
                    $to_focus.tabIndex = -1;
                    $to_focus.focus();
                    //Le scroll 0 et request animation frame sont importants pour que le header soit fermé quand on repositionne
                    window.scrollTo(0, 0);
                    window.requestAnimationFrame(function() {
                        $to_focus.scrollIntoView();
                    });
                } else {
                    var results = doc.querySelector('.archive-listing');
                    $wrapper.innerHTML = results.innerHTML;
                    $wrapper.tabIndex = -1;
                    $wrapper.focus();
                    window.scrollTo(0, 0);
                    window.requestAnimationFrame(function() {
                        $wrapper.scrollIntoView();
                    });
                }
                set_loaded_state();
                document.title = doc.title;
                window.history.pushState({}, "", this.responseURL);
            } else {
                handle_error.call(this);
            }
        }
    
        function handle_error()
        {
            set_loaded_state();
            throw new FetchResultsException(this);
        }
    
        function FetchResultsException(response)
        {
            this.message = "Error while fetching results from Admin-Ajax";
            this.response = response;
            this.toString = function() {
                return this.message;
            }
        }
    
        function set_loading_state()
        {
            $wrapper.classList.remove("archive-listing--loaded");
            $wrapper.classList.add("archive-listing--loading");
            const $notification = document.querySelector('.archive-listing__notification');
            $notification.innerHTML = archive_listing_messages.loading;
        }
    
        function set_loaded_state()
        {
            $wrapper.classList.add("archive-listing--loaded");
            $wrapper.classList.remove("archive-listing--loading");
            const $notification = document.querySelector('.archive-listing__notification');
            $notification.innerHTML = archive_listing_messages.loaded;
        }

        set_loading_state();

        //wp.ajax uses jQuery
        //We will do it ourselves
        let request = new XMLHttpRequest();
        const url = $link.href;


        request.open('GET', url, true);
        request.onload = handle_response;
        request.onerror = handle_error;
        request.send();
    }

    /**
     * Auto init all elements with a data-archive-filtres attribute
     */
    function init_archives_listing() {
        document.body.addEventListener('click', (e) => {
            if (e.target.classList.contains('page-numbers') || e.target.closest('.page-numbers')) {
                const $parent = e.target.closest('.archive-listing');
                const $link = e.target.classList.contains('page-numbers') ? e.target : e.target.closest('.page-numbers');
                if ($parent) {
                    e.preventDefault();
                    fetch_results($link, $parent);
                }
            }
        }, true);
    }

    if (typeof window.activis == "undefined") {
        window.activis = {};
    }
    window.activis.init_archives_listing = init_archives_listing;
    
    init_archives_listing();
})();
